import { useQuery } from '@tanstack/react-query'
import { prop } from 'ramda'

import { useAuth } from '@elvia/elvid-provider'
import { useCustomerParams } from '@elvia/minside-routing'
import { invalidateQueries, useKundeportalApi } from './ApiProvider'
import {
  ClientCustomerDto,
  EligibleProductionSharingMeteringPointDto,
  Permission,
  ProductionSharingContractDto,
} from './kundeportal-api'

export const useCustomers = <T = ClientCustomerDto[]>(
  select?: (data: ClientCustomerDto[]) => T
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
) => {
  const kundePortalApi = useKundeportalApi()
  const { isAuthenticated, user } = useAuth()

  const fetchCustomers = () =>
    kundePortalApi.web
      .customerList({
        PageSize: 5000,
      })
      .then(response => response.data)

  const { data, ...rest } = useQuery<ClientCustomerDto[], Error, T>({
    queryKey: ['useCustomers', user?.access_token],
    queryFn: fetchCustomers,
    enabled: isAuthenticated,
    select,
  })

  return {
    data: data || ([] as T),
    ...rest,
  }
}

export const invalidateUseCustomers = (): ReturnType<
  typeof invalidateQueries
> => {
  return invalidateQueries({ queryKey: ['useCustomers'] })
}

export const useSelectedCustomer = <T = ClientCustomerDto>(
  select?: (data: ClientCustomerDto) => T
): ReturnType<typeof useQuery<ClientCustomerDto, Error, T>> => {
  const kundePortalApi = useKundeportalApi()
  const { customerId } = useCustomerParams()
  const { isAuthenticated } = useAuth()

  const fetchCustomerById = () => {
    if (!customerId) throw new Error('No customer id')

    return kundePortalApi.customer.customerDetail(customerId).then(prop('data'))
  }

  return useQuery<ClientCustomerDto, Error, T>({
    queryKey: ['useSelectedCustomer', customerId],
    queryFn: fetchCustomerById,
    enabled: isAuthenticated && !!customerId,
    select,
  })
}

export const useCustomerPermission = (permission: Permission): boolean => {
  const { data: customer } = useSelectedCustomer()

  if (!customer) return false

  return !!customer.permissions?.includes(permission)
}

export const useProductionSharingContracts = <
  T = ProductionSharingContractDto[],
>(
  select?: (data: ProductionSharingContractDto[]) => T,
  customerId?: string
): ReturnType<typeof useQuery<ProductionSharingContractDto[], Error, T>> => {
  const kundePortalApi = useKundeportalApi()
  const { isAuthenticated } = useAuth()
  const { customerId: selectedCustomerId } = useCustomerParams()
  const customerIdToUse = customerId || selectedCustomerId

  const fetchProductionSharingContracts = () => {
    if (!customerIdToUse) throw new Error('No customer id')

    return kundePortalApi.customer
      .productionsharingcontractsDetail(customerIdToUse)
      .then(response => {
        return response.data.filter(
          (value, index, self) =>
            index === self.findIndex(t => t.id === value.id)
        )
      })
  }

  return useQuery({
    queryKey: ['useProductionSharingContracts', customerIdToUse],
    queryFn: fetchProductionSharingContracts,
    enabled: !!(isAuthenticated && customerIdToUse),
    select,
  })
}

export const useEligibleMeteringPointsToCreateProductionSharing = <
  T = string[],
>(
  canRequest: boolean = true,
  select?: (data: EligibleProductionSharingMeteringPointDto[]) => T,
  customerId?: string
): ReturnType<
  typeof useQuery<EligibleProductionSharingMeteringPointDto[], Error, T>
> => {
  const kundePortalApi = useKundeportalApi()
  const { isAuthenticated } = useAuth()
  const { customerId: selectedCustomerId } = useCustomerParams()
  const customerIdToUse = customerId || selectedCustomerId

  const fetchEligibleMeteringPointsToCreateProductionSharing = () => {
    if (!customerIdToUse) throw new Error('No customer id')

    return kundePortalApi.customer
      .productionsharingcontractsEligiblemeteringpointsDetail(customerIdToUse)
      .then(prop('data'))
  }

  return useQuery({
    queryKey: [
      'useEligibleMeteringPointsToCreateProductionSharing',
      customerIdToUse,
    ],
    queryFn: fetchEligibleMeteringPointsToCreateProductionSharing,
    enabled: !!(isAuthenticated && customerIdToUse && canRequest),
    select,
  })
}
